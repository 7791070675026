:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    --main-color: #C20001;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/DroidSerif.css";

/*
@import "/cssmodules/modules/photoswipe.css";
@import "/cssmodules/modules/animate.css";
*/

* {
    letter-spacing: 0 !important;
}

html {
    height: 100%;
    font-size: 16px;
    line-height: 1.5;
}

body {
    font-weight: 400;
    font-family: DroidSerif, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    background-image: url('/theme/intendit/img/bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    height:100%;
}

/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: 2.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--main-color);
}

h2 {
    font-size: 1.5rem;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

h3 {
    font-size: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

p {

    margin-top: 1rem;
    margin-bottom: 1rem;

}

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/
.button {
    padding: .4em 1em !important;
    background: #c20001 !important;
    color: #fff;
    border-radius: 10px !important;
}

header {
    background: transparent;
    border-bottom: 6px solid var(--main-color);
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--main-color);
    color: #fff;
}

header a {
    text-decoration: none;
}

.logo a { padding: 1rem;}

.startsida .topimage {

    height: 339px;
    background-size: cover;
    background-position: center right;


}

.flex figure img {

width:100%;
}

article > div  {

max-width:100%;
}


/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin:0;
}

.slick-track {
    height: 45vh;
}

.slick-track > div {
    background-size: cover;
}

/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
    background: #fff;
}



header, footer {
    flex: none;
}

.container {
    max-width: var(--container-max-width);

    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    outline: 0 !important;
}

.menu.menu, .submenu  {
    background: #F2F2F2
}

.menu.menu li {

    margin:0;

}

.menu.menu a, .submenu a {

    padding: 0.5rem;

}


.menu.menu a:hover, .submenu a:hover, .menu.menu > li.active > a, .submenu li.active a  {

    background: var(--main-color);
    color: #fff;
}

.submenu li {
    list-style: none;
 margin: .4rem 0;
}
.submenu a {
    text-decoration: none;
display: inline-block;
}


.callus {

    background: var(--main-color);
    color: #fff;
}

.boxes a {
    color: #fff; 
    text-decoration: none;
    text-align: center;
    background: var(--main-color);

}

.boxes a:nth-child(even) {
    color: #fff; 
    text-decoration: none;
    text-align: center;
    background: #353535;
}
.boxes a boxtitle {

    font-weight: 300;
 font-size: 1.5rem;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  display: block;
}

.boxes i  {
    font-size:3rem;
    display: block;
}

.boxwrapper {
    position: relative;
    text-shadow: 1px 2px 2px rgba(0,0,0,0.3);
  
}
.imgwrapper {position: relative;     display: block; 

    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;


}

.boxwrapper boxtitle {
    color: #fff;
    display: block;
}

.boxwrapper .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    background-color: rgba(0,0,0,0.0);
}

.boxwrapper:hover .overlay {
    background-color: rgba(0,0,0,0.6);
}

.boxwrapper .overlay span {
    text-align: center;
}

.boxwrapper .overlay i {
    opacity: 0;
   
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.boxwrapper:hover .overlay i {
    opacity: 1;
}

.boxwrapper:hover .overlay img {
    -webkit-filter: none;
    filter: none;
}

.boxwrapper img {
width: 100%;

}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {

}

/*--------------------------------------------------------------
Form
--------------------------------------------------------------*/

.formdiv {

    background: #C20001;
    color:#fff;
}


input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=file], input[type=month], input[type=number], input[type=password], input[type=phone], input[type=range], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], select, textarea {
    border: 0px solid #ccc;
    margin-bottom: 1em;
    padding: .3em .5em;
    border-radius: .1rem;
    max-width: 100%;
    color: black;
    width: 100%;
}


button[type=submit]:hover, input[type=submit]:hover {
    background: #121212;
    color: #bfbfbf;
}
button[type=submit], input[type=submit] {
    background: #343434;
    color: #fff;
}
.button, button, input[type=reset], input[type=submit] {
    text-decoration: none;
    border-radius: .2em;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: .5em 1em;
    text-align: center;
    outline: 0;
    border: 0px solid;
    background: 0 0;
}

.boltform label.required:after {
    content: " *";
    color: #fff;
    font-weight: bold;
}


@media (max-width: var(--breakpoint-m)) {}
@media (max-width: var(--breakpoint-s)) {

    .callus {order:-1;}
    .startsida h1 { font-size: 1.8rem;}
    .startsida .topimage {
    height: 339px;
    background-size: cover;
    background-position: center center;
}
}
@media (max-width: var(--breakpoint-xs)) {}
